<template>
  <div class="history">
    <h1>历史记录</h1>
    <main class="hmain">
      <div class="history_input">
        <label>起始日期</label>
        <input type="date" v-model="times[0]" placeholder="起始日期"/>
      </div>
      <div class="history_input">
        <label>结束日期</label>
        <input type="date" v-model="times[1]" placeholder="结束日期"/>
      </div>
      <div class="history_input">
        <label>主机</label>
        <input type="number" class="bh_input" v-model="mbh" placeholder="主机"/>
      </div>
      <div class="history_input">
        <label>从机</label>
        <input type="number" class="bh_input" v-model="sbh" placeholder="从机"/>
      </div>
      <el-button type="primary" @click="bt_history">查询</el-button>
    </main>
    <el-tabs v-model="activeName">
        <el-tab-pane label="数据" name="data">
          <el-table
            :data="$store.state.historys"
            style="width: 100%"
            :row-class-name="tableRowClassName">
            <el-table-column prop="id" label=""></el-table-column>
            <el-table-column prop="[1]" label="日期" width="100"></el-table-column>
            <el-table-column prop="[2]" label="时间"></el-table-column>
            <el-table-column prop="[3]" label="振动"></el-table-column>
            <el-table-column prop="[4]" label="温度"></el-table-column>
            <el-table-column prop="[5]" label="湿度"></el-table-column>
            <el-table-column prop="[6]" label="烟雾"></el-table-column>
            <el-table-column prop="[7]" label="电池电量"></el-table-column>
            <el-table-column prop="[8]" label="报警"></el-table-column>
            <el-table-column prop="[9]" label="纬度"></el-table-column>
            <el-table-column prop="[10]" label="经度"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="曲线" name="line">
          <el-tabs v-model="$store.state.current.chart" @tab-click="et_chart">
              <el-tab-pane label="振动" name="vibration">
              </el-tab-pane>
              <el-tab-pane label="温度" name="temperature">
              </el-tab-pane>
              <el-tab-pane label="湿度" name="humidity">
              </el-tab-pane>
              <el-tab-pane label="可燃气体含量" name="content">
              </el-tab-pane>
          </el-tabs>
          <HChart id="chart2" :update="update" />
        </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>

<script>
import axios from 'axios'
import HChart from '@/components/HChart.vue'

export default {
  components: {
    HChart
  },
  data() {
    return {
      mbh : '01',
      sbh : '0101',
      times: ['2020-12-31','2020-12-31'],
      update: 0,
      activeName: 'line'
    };
  },
  computed: {
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      // console.log('tableRowClassName', row, rowIndex, row[8], typeof(row[8]));
      row.id=rowIndex+1
      if (row);
      if (rowIndex);
      if (row[8] != '0000') {
        return 'warning-row';//'success-row'
      }
      return '';
    },
    et_chart(tab, event) {
      console.log(tab, event, this.chart);
      this.$store.commit('UI', {chart:this.$store.state.current.chart})
      this.update=this.update+1
    },
    bt_history(){
      var url='history.php'
      var ps={
        t1:this.times[0], 
        t2:this.times[1], 
        mbh:this.mbh, 
        sbh:this.sbh}
      var p=this
      axios.get(url, {params:ps}).then(function(rsp){
        console.log('rsp', typeof(rsp), rsp, rsp.data);
        if (Array.isArray(rsp.data)){
          p.$store.state.historys=rsp.data
        }
        p.update++;
      }).catch(function(err){
        console.log('err', err);
      })
      this.activeName='data'
    }
  },
  created () {
  },
  mounted () {
    var now=new Date()
    this.$set(this.times, 0, new Date(now.getFullYear(), now.getMonth(), now.getDate()).format('yyyy-MM-dd'))
    this.$set(this.times, 1, new Date(now.getFullYear(), now.getMonth(), now.getDate()).format('yyyy-MM-dd'))
    this.bt_history()
  }
};
</script>
