<template>
  <div class="param">
    <div id="param">
      <h1>参数设置</h1>
      <label for="vibration">振动阈值</label>
      <p><el-input type="number" v-model="vibration" placeholder="请输入内容"></el-input></p>

      <label for="temperature">温度阈值</label>
      <p><el-input type="number" v-model="temperature" placeholder="请输入内容"></el-input></p>
      
      <label for="humidity">湿度阈值</label>
      <p><el-input type="number" v-model="humidity" placeholder="请输入内容"></el-input></p>
      
      <label for="content">烟雾阈值</label>
      <p><el-input type="number" v-model="content" placeholder="请输入内容"></el-input></p>
      
      <label for="tel">电话(多电话用英文逗号分隔)</label>
      <el-input type="tel" v-model="tel" placeholder="请输入内容"></el-input>

      <el-button type="success" round @click="bt_save">保存</el-button>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      vibration: '',
      temperature: '',
      humidity: '',
      content: '',
      tel: '',
    }
  },
  created: function(){
    console.log('created', 'param');
    this.vibration=this.$store.state.param.vibration
    this.temperature=this.$store.state.param.temperature
    this.humidity=this.$store.state.param.humidity
    this.content=this.$store.state.param.content
    this.tel=this.$store.state.param.tel
  },
  methods: {
    bt_save(){
      this.$store.commit('Param', {
        vibration:this.vibration, 
        temperature:this.temperature, 
        humidity:this.humidity, 
        content:this.content, 
        tel:this.tel, 
        })
    },
  }
}
</script>

<style scoped>
#param {
  max-width: 480px;
  margin: auto;
}
</style>
