<template>
  <div class="hchart" id="hchart" style="height: 560px; margin: 0">
    <div :id="id" style="height: 100%;"></div>   
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'HChart',
  props: {
    id: String,
    update: Number,
  },
  data() {
    return {
      myChart : null,
      option : {
        animation : false,
        xAxis: {
          type: 'time',
          splitLine: {
            show: true
          },
          minInterval: 3600*1000,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
          splitLine: {
            show: true
          },
        },
        series: [
          {
            name: 'Fake Data',
            type: 'line',
            showSymbol: false,
            data: [],
          },
          {
            name: 'Fake Data2',
            type: 'line',
            showSymbol: true,
            data: [],
          },
        ]
      },
    }
  },
  watch:{
    update(v){
      console.log('HChart watch update', typeof(v), v);
      this.Refresh()
    }
  },
  methods: {
    Init () {
      var chartDom = document.getElementById(this.id);
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption(this.option);
      window.onresize = this.myChart.resize;
    },

    Refresh(){
      var ds=[]
      this.$store.state.historys.forEach(e => {
        ds.push({value:[e[1]+' '+e[2] , e[this.$store.state.current.chart_i-9]]})
      });
      this.option.series[0].data=ds
      if (ds.length>=2) {
        var v0={value:[]}
        var v1={value:[]}
        v0.value[0]=ds[0].value[0]
        v0.value[1]=this.$store.state.current.param
        v1.value[0]=ds[ds.length-1].value[0]
        v1.value[1]=this.$store.state.current.param
        this.option.series[1].data=[v0,v1]
      }
      this.myChart.setOption(this.option)
    }
  },
  created: function(){
  },
  mounted: function(){
    console.log('HChart mounted', this.id);
    this.Init()
  },
}
</script>
